

import { Basebuilder } from "./basebuilder";
import { Contact } from "../viewModels/contact";
export class ContactBuilder extends Basebuilder<Contact> {
  private mail: string = '';
  private phone: string = '';

  public wtihMail(email: string): this { this.mail = email; return this; }
  public withPhone(phone: string): this { this.phone = phone; return this; }

  override build(): Contact {
    return {
      mail: this.mail,
      phone: this.phone
    };
  }

}
