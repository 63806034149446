
import { type Catalog } from '@models/viewModels/catalog/catalog';
import { type CatalogItem } from '@models/viewModels/catalog/catalog-item';
import { Basebuilder } from '@models/builders/basebuilder';

export class CatalogBuilder extends Basebuilder<Catalog> {

  private name: string = '';
  private dataAreaId: string = '';
  private parentName: string = '';
  private items: CatalogItem[] = [];
  private nodeId: number = 0;

  public withName(name: string): this { this.name = name; return this; }
  public withDataAreaId(dataAreaId: string): this { this.dataAreaId = dataAreaId; return this; }
  public withParentId(parentName: string): this { this.parentName = parentName; return this; }
  public withItems(items: CatalogItem[]): this { this.items = [...items]; return this; }
  public wtihNodeId(nodeId: number): this { this.nodeId = nodeId; return this; }

  build(): Catalog {
    return {
      description: this.name,
      dataAreaId: this.dataAreaId,
      referenceName: this.parentName,
      items: this.items,
      nodeId: this.nodeId
    };
  }
}
