import { PersonBuilder } from './person.builder';
import { User } from "../viewModels/user";
import { Basebuilder } from "./basebuilder";
import { UserRole } from '../enums/user-role.enum';
import { Customer } from '../viewModels/customer';
import { Person } from '../viewModels/person';

export class UserBuilder extends Basebuilder<User> {
  private person: Person = new PersonBuilder().build();
  private alias: string = '';
  private role: UserRole = UserRole.NONE;
  private customerProfiles: Customer[] = [] as Customer[];
  private registerNumber: string = '';
  private defaultDataAreaId: string = '';
  private language: string = '';
  private logisticEmployeeId: number = 0;

  public withPerson(person: Person): this { this.person = person  ; return this; }
  public withAlias(alias: string): this { this.alias = alias; return this; }
  public withRole(role : UserRole): this { this.role = role  ; return this; }
  public withCustomerProfiles(profiles: Customer[]): this { this.customerProfiles = profiles; return this; }
  public withRegisterNumber(registerNumber: string): this { this.registerNumber = registerNumber; return this; }
  public withDefaultDataAreaId(dataAreaId: string): this { this.defaultDataAreaId = dataAreaId; return this; }
  public withLanguage(lang: string): this { this.language = lang; return this; }
  public withLogisticEmployeeId(employeeId: number): this { this.logisticEmployeeId = employeeId; return this; }

  override build(): User  {
    return {
      logisticEmployeeId: this.logisticEmployeeId,
      person: this.person,
      alias: this.alias,
      role: this.role,
      customerProfiles: this.customerProfiles,
      registerNumber: this.registerNumber,
      defaultDataAreaId: this.defaultDataAreaId,
      language : this.language
    };
  }

}
