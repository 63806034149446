/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-namespace */
export const SharedConsts = {
  domaine: {
    constA:'A',
    constB: 'B',
    AndSoOn:'...'
  }
  ,EMPTY_DEFAULT_TIMESTAMP_TWELVE : "1900-01-01T12:00:00.000Z"
  ,EMPTY_DEFAULT_TIMESTAMP_ZERO : "1900-01-01T00:00:00.000Z"
};


