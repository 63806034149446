
import { type Customer } from '@models/viewModels/customer';
import { type Order } from '@models/viewModels/order/order';
import { type OrderHeader } from '@models/viewModels/order/order-header';
import { type OrderLine } from '@models/viewModels/order/order-line';
import { Basebuilder } from '@models/builders/basebuilder';
import { OrderHeaderBuilder } from '@models/builders/order-header.builder';

export class OrderBuilder extends Basebuilder<Order> {
  private lines: OrderLine[] = [];
  private header: OrderHeader = new OrderHeaderBuilder().build();
  private customer: Customer = {} as Customer;

  public withLines (lines: OrderLine[]): this { this.lines = [...lines]; return this; }
  public withAddedLine (line: OrderLine): this { this.lines.push(line); return this; }
  public withHeader (header: OrderHeader): this { this.header = { ...header }; return this; }
  public withCustomer (customer: Customer): this { this.customer = { ...customer }; return this; }

  build (): Order {
    return {
      lines: this.lines,
      header: this.header,
      customer: this.customer
    };
  }
}
