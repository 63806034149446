import { SharedConsts } from '@shared/utils/consts';

/**
 * Module for validating date formats, comparing dates, and checking date properties.
 */
export const DateValidator = {

  /**
   * Validates if a date string matches the specified format.
   * @param date The date string to validate.
   * @param format The format to check against (e.g., 'DD/MM/YYYY').
   * @returns True if the date string matches the format, false otherwise.
   */
  isValidDateFormat(date: string, format: string): boolean {
    if (format === 'DD/MM/YYYY') {
      // Regular expression for 'DD/MM/YYYY' format
      const regex = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
      return regex.test(date);
    }

    // Add additional formats if necessary
    return false;
  },

  /**
   * Validates if a date string is not null, undefined, and not a default invalid date.
   * @param date The date string to be validated.
   * @returns True if the date string is valid, false otherwise.
   */
  isValidDateString: function (date: string | null | undefined): boolean {
    const invalidDate = new Date("1900-01-01");
    const dateObj = new Date(date || '');
    return date !== null && date !== undefined && dateObj.getTime() !== invalidDate.getTime();
  },

  /**
   * Checks if a Date object is valid and not a default timestamp.
   * @param date The Date object to be checked.
   * @returns True if the Date object is valid and not a default date, false otherwise.
   */
  isValidDate: function (date: Date | null | undefined): boolean {
    if (!date) {
      return false;
    }
    if (date === null || date === undefined) {
      return false;
    }
    return date.toISOString() !== SharedConsts.EMPTY_DEFAULT_TIMESTAMP_TWELVE && date.toISOString() !== SharedConsts.EMPTY_DEFAULT_TIMESTAMP_ZERO;
  },

  /**
   * Compares two date strings to check if the first date is before the second date.
   * @param date1 The first date string.
   * @param date2 The second date string.
   * @returns True if date1 is before date2, false otherwise.
   */
  isDateBefore: function (date1: string, date2: string): boolean {
    return new Date(date1) < new Date(date2);
  },

  /**
   * Compares two date strings to check if the first date is after the second date.
   * @param date1 The first date string.
   * @param date2 The second date string.
   * @returns True if date1 is after date2, false otherwise.
   */
  isDateAfter: function (date1: string, date2: string): boolean {
    return new Date(date1) > new Date(date2);
  },

  /**
   * Checks if a person is at least a certain age based on their birth date.
   * @param birthDate The birth date string.
   * @param minAge The minimum age to check.
   * @returns True if the person is at least the minimum age, false otherwise.
   */
  isMinimumAge: function (birthDate: string, minAge: number): boolean {
    const today = new Date();
    const birth = new Date(birthDate);
    const age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      return age > minAge;
    }
    return age >= minAge;
  },

  /**
   * Compares two Date objects using ISO format.
   * @param date1 The first Date object.
   * @param date2 The second Date object.
   * @returns -1 if date1 is before date2, 1 if date1 is after date2, 0 if they are equal.
   */
  compareDatesIsoFormat: function (date1: Date, date2: Date): number {
    const dateString1 = date1.toISOString();
    const dateString2 = date2.toISOString();

    if (dateString1 < dateString2) {
      return -1;
    } else if (dateString1 > dateString2) {
      return 1;
    } else {
      return 0;
    }
  },

  /**
   * Converts a date string to a Date object in ISO format.
   * @param dateString The date string to convert.
   * @returns The Date object representing the input date string.
   */
  stringToIsoDate: function (dateString: string): Date {
    const timestamp = Date.parse(dateString);
    const dateEnFormat = new Date(timestamp).toISOString();
    return new Date(dateEnFormat);
  },

  /**
   * Checks if the first date (comparer) is before the second date (value).
   * @param comparer The first date to compare.
   * @param value The second date to compare.
   * @returns True if comparer is before value, false otherwise.
   */
  isBefore: function (comparer: Date | string, value: Date | string): boolean {
    const date1 = typeof comparer === 'string' ? this.stringToIsoDate(comparer) : comparer;
    const date2 = typeof value === 'string' ? this.stringToIsoDate(value) : value;
    return this.compareDatesIsoFormat(date1, date2) < 0;
  },

  /**
   * Checks if the first date (comparer) is after the second date (value).
   * @param comparer The first date to compare.
   * @param value The second date to compare.
   * @returns True if comparer is after value, false otherwise.
   */
  isAfter: function (comparer: Date | string, value: Date | string): boolean {
    const date1 = typeof comparer === 'string' ? this.stringToIsoDate(comparer) : comparer;
    const date2 = typeof value === 'string' ? this.stringToIsoDate(value) : value;
    return this.compareDatesIsoFormat(date1, date2) > 0;
  },

  /**
   * Checks if a Date object is not null and not equal to a default timestamp.
   * @param date The Date object to check.
   * @returns True if the Date object is not null and not a default date, false otherwise.
   */
  isNotNullAndNotADefaultDate: function (date: Date | null | undefined): boolean {
    if (date === null || date === undefined || !(date instanceof Date)) {
      return false;
    }
    const time1 = new Date(SharedConsts.EMPTY_DEFAULT_TIMESTAMP_TWELVE);
    const time2 = new Date(SharedConsts.EMPTY_DEFAULT_TIMESTAMP_ZERO);
    return time1.getTime() !== date.getTime() && time2.getTime() !== date.getTime();
  }
};
