
import { CustomerType } from '@app/core/models/enums/customer-type.enum';
import { type Customer } from '@models/viewModels/customer';
import { Basebuilder } from '@models/builders/basebuilder';
import { type Person } from '@models/viewModels/person';
import { type Catalog } from '@models/viewModels/catalog/catalog';
import { PersonBuilder } from './person.builder';
import { CatalogBuilder } from './catalog.builder';

export class CustomerBuilder extends Basebuilder<Customer> {
  private number: string = '';
  private type: CustomerType = CustomerType.TP;
  private dataAreaId: string = '';// ENUM
  private person: Person = new PersonBuilder().build();
  private catalog: Catalog = new CatalogBuilder().build();

  public withNumber (number: string): this { this.number = number; return this; }
  public withFirstName (firstName: string): this { this.person.firstName = firstName; return this; }
  public withLastName (lastName: string): this { this.person.lastName = lastName; return this; }
  public withType (type: CustomerType): this { this.type = type; return this; }
  public withDataAreaId (dataAreaId: string): this { this.dataAreaId = dataAreaId; return this; }
  public withPerson (person: Person): this { this.person = person; return this; }
  public withCatalog (catalog: Catalog): this { this.catalog = catalog; return this; }

  build (): Customer {

    return {
      number: this.number,
      type: this.type,
      dataAreaId: this.dataAreaId,
      contactPerson: this.person,
      catalog: this.catalog
    };
  }
}
