
import { type OrderLine } from '@models/viewModels/order/order-line';
import { type Article } from '@app/core/models/viewModels/article';
import { Basebuilder } from '@models/builders/basebuilder';
import { ArticleBuilder } from '@app/core/models/builders/article.builder';
import { LineStatus } from '../enums/line-status';

export class OrderlineBuilder extends Basebuilder<OrderLine> {
  private dataAreaId: string = '';
  private orderNumber: string = '';
  private quantity: number = 1;
  private article: Article = new ArticleBuilder().build();
  private lineTotal: number = 1;
  private lineStatus: LineStatus = LineStatus.UNKNOWN;
  private description: string = '';
  private id: string = "";
  private masterId: number = 0;
  private ismaster: boolean = true;

  public withDataAreaId (dataAreaId: string): this { this.dataAreaId = dataAreaId; return this; }
  public withOrderNumber (orderNumber: string): this { this.orderNumber = orderNumber; return this; }
  public withQuantity (quantity: number): this { this.quantity = quantity; return this; }
  public withArticle (article: Article): this { this.article = { ...article }; return this; }
  public withLineTotal (total: number): this { this.lineTotal = total; return this; }
  public withLineStatus (status: LineStatus): this { this.lineStatus = status; return this; }
  public withDescription (description: string): this { this.description = description; return this; }
  public withId (id: string): this { this.id = id; return this; }
  public withMasterId (id: number): this { this.masterId = id; return this; }
  public withIsMaster (ismaster: boolean): this { this.ismaster = ismaster; return this; }
  override build (): OrderLine {
    return {
      dataAreaId: this.dataAreaId,
      orderNumber: this.orderNumber,
      quantity: this.quantity,
      article: this.article,
      lineTotal: this.lineTotal,
      lineStatus: this.lineStatus,
      description: this.description,
      id: this.id,
      masterid: this.masterId,
      ismaster: this.ismaster
    };
  }
}
