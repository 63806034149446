import { Contact } from "../viewModels/contact";
import { Person } from "../viewModels/person";
import { Basebuilder } from "./basebuilder";
import { ContactBuilder } from "./contact.builder";

export class PersonBuilder extends Basebuilder<Person> {
  // protected person: Person = { ref: '', firstName: '', lastName: '', contact: { mail: '', phone: '' } };

  private ref: string = "";
  private fisrtName: string = "";
  private lastName: string = "";
  private contact: Contact = new ContactBuilder().build();

  public withRef(ref: string) { this.ref = ref; return this; }
  public withFisrtName(firstName: string) { this.fisrtName = firstName; return this; }
  public withLastName(lastName: string) { this.lastName = lastName; return this; }
  public withContact(contact: Contact) { this.contact = contact; return this; }

  override build(): Person {
    return {
      ref: this.ref,
      lastName: this.lastName,
      firstName: this.fisrtName,
      contact: this.contact
    };
  }

}
