import { OrderStatus } from '@app/core/models/enums/order-status.enum';
import { type OrderHeader } from '@models/viewModels/order/order-header';
import { Basebuilder } from '@models/builders/basebuilder';
import { SharedConsts } from '@app/shared/utils/consts';

export class OrderHeaderBuilder extends Basebuilder<OrderHeader> {
  private dataAreaId: string = '';
  private orderNumber: string = '';
  private memo: string = '';
  private orderDate: Date = new Date();
  private orderStatus: OrderStatus = OrderStatus.UNKNOWN;
  private orderLinkUrl: string = '';
  private deliveryAddress: string = '';
  private deliveryDate: Date = new Date();
  private deliveryMode: string = '';
  private customerNumber: string = '';
  private customerFullName: string = '';
  private assignment: number = 0;
  private haveIntegrationErrors: boolean = false;
  private intercoShippingDate: Date=new Date(SharedConsts.EMPTY_DEFAULT_TIMESTAMP_TWELVE);


  public withDataAreaId(dataAreaId: string): this { this.dataAreaId = dataAreaId; return this; }
  public withOrderNumber(orderNumber: string): this { this.orderNumber = orderNumber; return this; }
  public withMemo(memo: string): this { this.memo = memo; return this; }
  public withOrderDate(date: Date): this { this.orderDate = date; return this; }
  public withOrderStatus(status: OrderStatus): this { this.orderStatus = status; return this; }
  public withOrderLinkUrl(url: string): this { this.orderLinkUrl = url; return this; }
  public withDeliveryAddress(address: string): this { this.deliveryAddress = address; return this; }
  public withDeliveryMode(deliveryMode: string): this { this.deliveryMode = deliveryMode; return this; }
  public withDeliveryDate(date: Date): this { this.deliveryDate = date; return this; }
  public withCustomerNumber(customerNumber: string): this { this.customerNumber = customerNumber; return this; }
  public withCustomerFullName(customerFullName: string): this { this.customerFullName = customerFullName; return this; }
  public withLocking(assigned: number): this { this.assignment = assigned; return this; }
  public withIntegrationErrors(): this { this.haveIntegrationErrors = true; return this; }
  public withItercoShippingDate(intercoShippingDate: Date): this { this.intercoShippingDate = intercoShippingDate; return this; }


  build(): OrderHeader {
    return {
      dataAreaId: this.dataAreaId,
      orderNumber: this.orderNumber,
      memo: this.memo,
      orderDate: this.orderDate,
      orderStatus: this.orderStatus,
      orderLinkUrl: this.orderLinkUrl,
      deliveryAddress: this.deliveryAddress,
      deliveryDate: this.deliveryDate,
      deliveryMode: this.deliveryMode,
      customerNumber: this.customerNumber,
      customerFullName: this.customerFullName,
      assignment: this.assignment,
      haveErrorIntegration: this.haveIntegrationErrors,
      intercoShippingDate: this.intercoShippingDate

    };
  }
}
