
import { OrderStatus } from '@app/core/models/enums/order-status.enum';
import { Basebuilder } from '@models/builders/basebuilder';
import { OrderResume } from '../viewModels/order/order-resume';

export class OrderResumeBuilder extends Basebuilder<OrderResume> {
  private orderStatus: OrderStatus = OrderStatus.UNKNOWN;
  private haveIntegrationErrors: boolean = false;
  private ordernumber: string = "";
  private dataAreaId: string = "";
  private haveInterco: boolean = false ;

  public withOrderStatus (status: OrderStatus): this { this.orderStatus = status; return this; }
  public withIntegrationErrors (): this { this.haveIntegrationErrors = true; return this; }
  public withOrderNumber (number: string) : this { this.ordernumber = number; return this; }
  public withDataAreaId (dataAreaID: string) : this { this.dataAreaId = dataAreaID; return this; }
  public withIntercoDate (any: boolean) : this {this.haveInterco = any; return this; }


  build (): OrderResume {
    return {
      orderStatus: this.orderStatus,
      haveErrorIntegration: this.haveIntegrationErrors,
      orderNumber: this.ordernumber,
      dataAreaId : this.dataAreaId,
      haveInterco : this.haveInterco
    };
  }
}
